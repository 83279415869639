﻿export * from "./StateResponse";

export interface IEnvConfig {
    apiUrl: string;
    aiConnectionString: string;
    aiCloudRole: string;
    aiCloudRoleInstance: string;
}

export enum CandidatePhase {
    Survey = "Survey",
    Dashboard = "Dashboard",
    Complete = "Complete",
    OptedOut = "OptedOut",
}

export type LoginRequest = {
    token?: string;
    last4?: string;
    dob?: string;
};

export type LoginResponse = {
    token: string;
    candidatePhase: CandidatePhase;
};

export type ConfigResponse = {
    logoPath: string;
    themePath?: string | null;
    clientName: string;
    introText: string;
    introLegal: string;
    thankYouText: string;
};
