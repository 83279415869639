﻿import { CandidatePhase, LoginResponse } from "../api";
import { useNavigate } from "react-router-dom";
import { useSqId } from "../context/SqIdContext";

export function useHandleLoginResponse() {
    const sqId = useSqId();
    const navigate = useNavigate();

    return (response: LoginResponse) => {
        switch (response.candidatePhase) {
            case CandidatePhase.Survey:
                navigate(`/${sqId}/candidate`);
                break;
            case CandidatePhase.Dashboard:
                navigate(`/${sqId}/dashboard`);
                break;
            case CandidatePhase.OptedOut:
                navigate(`/${sqId}/optedOut`);
                break;
            case CandidatePhase.Complete:
                navigate(`/${sqId}/alreadyCompleted`);
        }
    };
}
