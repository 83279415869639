﻿import { LoginRequest } from "../api";
import { useSearchParams } from "react-router-dom";
import { useMemo } from "react";

export function useLoginRequest() {
    const [searchParams] = useSearchParams();

    return useMemo(() => {
        const result: LoginRequest = {};

        const token = searchParams.get("token");
        if (token) {
            result["token"] = token;
        }

        return result;
    }, [searchParams]);
}
