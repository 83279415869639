﻿import { FunctionComponent, useEffect } from "react";
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input } from "@chakra-ui/react";
import { ApplicationLayout } from "../../components/ApplicationLayout";
import { useTranslation } from "react-i18next";
import { useLogin } from "../../api/apiQueries";
import { LoginRequest } from "../../api";
import { useLoginRequest } from "../../hooks/useLoginRequest";
import { useHandleLoginResponse } from "../../hooks/useHandleLoginResponse";
import { SubmitHandler, useForm } from "react-hook-form";
import { DatePicker } from "am-tax-fe-core";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";

type FormValues = LoginRequest;

export const LoginPage: FunctionComponent<{ fixed?: boolean }> = () => {
    const { t } = useTranslation();
    const appInsights = useAppInsightsContext();

    useEffect(() => {
        appInsights.trackPageView({
            uri: "/questionnaire/login",
            name: "Questionnaire: Login",
        });
    }, []);

    //eager fetch the dashboard page but do it after the page has loaded off the main thread
    useEffect(() => {
        const timeout = setTimeout(() => {
            import("../CandidateDashboardPage");
        }, 1500);

        return () => clearTimeout(timeout);
    }, []);

    const loginRequest = useLoginRequest();
    const loginQuery = useLogin();
    const handleLoginResponse = useHandleLoginResponse();

    const login: SubmitHandler<FormValues> = async data => {
        const response = await loginQuery.mutateAsync({ ...loginRequest, ...data });
        handleLoginResponse(response);
    };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormValues>({
        values: loginRequest,
    });

    if (!loginRequest.token) {
        return <ApplicationLayout content={<Heading>Invalid Login URL</Heading>} />;
    }

    return (
        <form onSubmit={handleSubmit(login)}>
            <ApplicationLayout
                content={
                    <>
                        <Heading>{t("login.heading")}</Heading>
                        <fieldset disabled={loginQuery.isLoading}>
                            <FormControl isInvalid={!!errors?.last4}>
                                <FormLabel>{t("login.last4")}:</FormLabel>
                                <Input
                                    placeholder={t("login.last4Placeholder")}
                                    pattern="[0-9]{4}"
                                    {...register("last4", { required: t("fieldRequired", { field: t("login.last4") }) })}
                                />
                                <FormErrorMessage>{errors?.last4?.message}</FormErrorMessage>
                            </FormControl>
                            <FormControl isInvalid={!!errors?.dob}>
                                <FormLabel>{t("login.dob")}:</FormLabel>
                                <DatePicker {...register("dob", { required: t("fieldRequired", { field: t("login.dob") }) })} />
                                <FormErrorMessage>{errors?.dob?.message}</FormErrorMessage>
                            </FormControl>
                        </fieldset>
                    </>
                }
                buttons={
                    <Flex justifyContent={"end"} width={"100%"}>
                        <Button type={"submit"} variant={"primary"}>
                            {t("login.login")}
                        </Button>
                    </Flex>
                }
            />
        </form>
    );
};
