import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "./queryKeys";
import {
    configureAuthToken,
    finishQuestionnaire,
    getCandidate,
    login,
    getQuestionnaireDefinition,
    getQuestionnaireValues,
    getStates,
    optOut,
    putCandidate,
    saveQuestionnaireValues,
    signForm,
    getConfig,
    getForm,
    SignaturePin,
    UploadDocumentArgs,
    uploadDocument,
    getRequests,
    deleteDocument,
} from "./api";
import { Operation } from "rfc6902/diff";
import { Candidate, CandidateArgs } from "../common";
import { LoginRequest, LoginResponse } from "./models";
import { useSqId } from "../context/SqIdContext";
import { useTranslation } from "react-i18next";

export function useConfig() {
    const sqId = useSqId();
    const { i18n } = useTranslation();
    return useQuery(queryKeys.config.byLanguage(sqId, i18n.language).queryKey, () => getConfig(sqId));
}

export function useLogin() {
    const sqId = useSqId();
    return useMutation((loginRequest: LoginRequest) => login(sqId, loginRequest), {
        onSuccess: (response: LoginResponse) => {
            configureAuthToken(response.token);
        },
        meta: {
            errorMessage: "Unable to save personal details",
        },
    });
}

export function useCandidate() {
    const sqId = useSqId();
    return useQuery(queryKeys.candidates.me(sqId).queryKey, () => getCandidate(sqId));
}
export function useSaveCandidate() {
    const sqId = useSqId();
    const queryClient = useQueryClient();
    return useMutation((args: CandidateArgs) => putCandidate(sqId, args), {
        onSuccess: async (response: Candidate) => {
            await queryClient.setQueryData(queryKeys.candidates.me(sqId).queryKey, response);
        },
        meta: {
            errorMessage: "Unable to save personal details",
        },
    });
}

export function useQuestionnaireDefinition() {
    const sqId = useSqId();
    const { i18n } = useTranslation();
    return useQuery(queryKeys.questionnaire.byLanguage(sqId, i18n.language).queryKey, () => getQuestionnaireDefinition(sqId));
}

export function useQuestionnaireValuesQueryFn() {
    const sqId = useSqId();
    const queryClient = useQueryClient();
    return async function (): Promise<Record<string, string>> {
        return queryClient.fetchQuery(queryKeys.questionnaire.values(sqId).queryKey, () => getQuestionnaireValues(sqId));
    };
}

export function useSaveQuestionnaireValues() {
    const sqId = useSqId();
    const queryClient = useQueryClient();
    return useMutation(
        (operations: Operation[]) => {
            return saveQuestionnaireValues(sqId, operations);
        },
        {
            onSuccess: async data => {
                queryClient.setQueryData(queryKeys.questionnaire.values(sqId).queryKey, data);
            },
            meta: {
                errorMessage: "Unable to save selection",
            },
        }
    );
}

export function useFinishQuestionnaire() {
    const sqId = useSqId();
    return useMutation(() => finishQuestionnaire(sqId));
}

export function useOptOut() {
    const sqId = useSqId();
    return useMutation(() => optOut(sqId));
}
export function useTaxForm() {
    const sqId = useSqId();
    return useQuery(queryKeys.form.f8850(sqId).queryKey, () => getForm(sqId));
}

export function useSignTaxForm() {
    const sqId = useSqId();
    return useMutation((args: SignaturePin) => signForm(sqId, args));
}

export function useRequests() {
    const sqId = useSqId();
    return useQuery(queryKeys.requests.bySqId(sqId).queryKey, () => getRequests(sqId));
}

export function useUploadDocumentToRequest() {
    const sqId = useSqId();
    const queryClient = useQueryClient();
    return useMutation(
        async (args: UploadDocumentArgs) => {
            return await uploadDocument(sqId, args);
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(queryKeys.requests.bySqId(sqId).queryKey);
            },
            meta: {
                errorMessage: "Unable to upload document",
            },
        }
    );
}

export function useDeleteDocumentFromRequest() {
    const sqId = useSqId();
    const queryClient = useQueryClient();
    return useMutation(
        async ({ requestId, documentId }: { requestId: string; documentId: string }) => {
            return await deleteDocument(sqId, requestId, documentId);
        },
        {
            onSuccess: async () => {
                await queryClient.invalidateQueries(queryKeys.requests.bySqId(sqId).queryKey);
            },
            meta: {
                errorMessage: "Unable to delete document",
            },
        }
    );
}

export function useStates() {
    return useQuery(queryKeys.states.all.queryKey, getStates, {
        staleTime: Infinity,
        cacheTime: Infinity,
        meta: {
            errorMessage: "failed to retrieve states",
        },
    });
}
