﻿// Error
import { Candidate, Questionnaire, Request } from "../common";
import { Operation } from "rfc6902/diff";
import { ConfigResponse, IEnvConfig, LoginResponse } from "./";
import { ChakraTheme } from "@chakra-ui/react";
import { LoginRequest, StateResponse } from "./models";

export type ErrorResponseBody = { errorMessage?: string };
export type ErrorResponse = { status: number; body: ErrorResponseBody };

let baseUrl = "https://localhost:7282";
export const configureBaseUrl = (url: string) => {
    baseUrl = url.endsWith("/") ? url.substring(0, url.length - 1) : url;
};

export const configureAuthToken = (newToken: string) => {
    sessionStorage.setItem("authToken", newToken);
};

async function get(url: string): Promise<object> {
    return doFetch(url);
}

async function post(url: string, requestBody: object, returnsVoid = false): Promise<unknown> {
    return doFetch(url, returnsVoid, {
        method: "POST",
        body: JSON.stringify(requestBody),
    });
}

async function put(url: string, requestBody: object): Promise<object> {
    return doFetch(url, false, {
        method: "PUT",
        body: JSON.stringify(requestBody),
    });
}

async function patch(url: string, requestBody: object): Promise<object> {
    return doFetch(url, false, {
        method: "PATCH",
        body: JSON.stringify(requestBody),
    });
}

async function doDelete(url: string): Promise<unknown> {
    return doFetch(url, true, {
        method: "DELETE",
    });
}

function doFetchOther(url: string, init?: RequestInit): Promise<Response> {
    return doFetch(url, true, init) as Promise<Response>;
}

async function doFetch(url: string, dontParse = false, init?: RequestInit): Promise<object | Response> {
    const absoluteUrl = `${baseUrl}${url}`;
    const headers = new Headers(init?.headers);
    headers.set("Content-Type", "application/json");
    const authToken = sessionStorage.getItem("authToken");
    if (authToken) {
        headers.append("Authorization", `bearer ${authToken}`);
    }
    const language = localStorage.getItem("i18nextLng");
    if (language) {
        headers.append("Accept-Language", language);
    }
    const response = await fetch(absoluteUrl, { ...init, headers });
    if (response.ok) {
        if (dontParse) {
            return response;
        } else {
            return await response.json();
        }
    } else {
        const data = await response.json();
        throw { status: response.status, body: data } as ErrorResponse;
    }
}

export interface UploadCallbacks {
    progress: (e: ProgressEvent<EventTarget>) => void;
    success: () => void;
    error: (e: ErrorResponse) => void;
}

export interface FileContainer {
    index: number;
    file: File;
    callbacks: UploadCallbacks;
}

async function downloadFileWithProgress(url: string, progressCallback: (progress: number) => void): Promise<Response> {
    const response = (await doFetch(url, true)) as Response;
    const contentLength = response.headers.get("content-length");
    let total = 0;
    if (contentLength) {
        total = parseInt(contentLength, 10);
    }
    let loaded = 0;

    if (response.body && total > 0) {
        const reader = response.body.getReader();
        const stream = new ReadableStream<Uint8Array>({
            start(controller) {
                function pump(): Promise<void> {
                    return reader.read().then(({ done, value }) => {
                        if (done) {
                            controller.close();
                            return;
                        }

                        loaded += value.byteLength;

                        const progress = Math.round((loaded / total) * 100);
                        // console.log(progress);
                        progressCallback(progress);

                        controller.enqueue(value);
                        return pump();
                    });
                }

                return pump();
            },
        });

        return new Response(stream);
    } else {
        return response;
    }
}

/**
 * This is a custom built upload function that will allow us to upload files and get progress updates
 * @param url
 * @param fileContainer
 */
function uploadFile<T>(url: string, fileContainer: FileContainer): Promise<T> {
    return new Promise<T>((res, rej) => {
        const xhr = new XMLHttpRequest();
        const absoluteUrl = `${baseUrl}${url}`;
        xhr.open("POST", absoluteUrl);

        //sending the first progress event immediately even though we haven't technically started the upload let's the users know that we are working on uploading this file.
        const initialEvent = new ProgressEvent("upload", { lengthComputable: false });
        fileContainer.callbacks.progress(initialEvent);

        const authToken = sessionStorage.getItem("authToken");
        if (authToken) {
            xhr.setRequestHeader("Authorization", `bearer ${authToken}`);
        }

        xhr.onload = () => {
            if (200 <= xhr.status && xhr.status < 300) {
                const result: T = JSON.parse(xhr.responseText) as T;
                fileContainer.callbacks.success();
                res(result);
            } else {
                const result: ErrorResponseBody = JSON.parse(xhr.responseText) as ErrorResponseBody;
                fileContainer.callbacks.error({ status: xhr.status, body: result });
                rej(result);
            }
        };

        xhr.onprogress = evt => {
            fileContainer.callbacks.progress(evt);
        };

        xhr.onerror = evt => {
            rej(evt);
        };

        const formData = new FormData();
        formData.append("file", fileContainer.file);
        xhr.send(formData);
    });
}

export const getEnvConfig = () =>
    fetch("/env.json").then(response => {
        if (!response.ok) {
            throw new Error(response.statusText);
        }
        return response.json() as Promise<IEnvConfig>;
    });

export const getConfig = (sqId: string): Promise<ConfigResponse> => {
    return get(`/api-candidate/${sqId}/config`) as Promise<ConfigResponse>;
};

export const getTheme = async (themePath: string): Promise<Partial<ChakraTheme> | null> => {
    try {
        const response = await fetch(themePath);
        if (response.ok) {
            return (await response.json()) as Promise<Partial<ChakraTheme>>;
        } else {
            console.log(`Error loading theme from ${themePath}`);
        }
    } catch (e) {
        console.log(`Error loading theme from ${themePath}`);
    }
    return null;
};

export const login = (sqId: string, loginRequest: LoginRequest): Promise<LoginResponse> => {
    if (loginRequest.token) {
        const { last4, dob } = loginRequest;
        return post(`/api-candidate/${sqId}/account/signin`, { token: loginRequest.token, last4, dob }) as Promise<LoginResponse>;
    } else {
        return post(`/api-candidate/${sqId}/account`, loginRequest) as Promise<LoginResponse>;
    }
};

export const getCandidate = (sqId: string): Promise<Candidate> => {
    return get(`/api-candidate/${sqId}/details`) as Promise<Candidate>;
};
export const putCandidate = (sqId: string, candidate: Candidate): Promise<Candidate> => {
    return put(`/api-candidate/${sqId}/details`, candidate) as Promise<Candidate>;
};

export const getQuestionnaireDefinition = (sqId: string): Promise<Questionnaire> => {
    return get(`/api-candidate/${sqId}/questionnaire/definition`) as Promise<Questionnaire>;
};

export const getQuestionnaireValues = (sqId: string): Promise<Record<string, string>> => {
    return get(`/api-candidate/${sqId}/questionnaire`) as Promise<Record<string, string>>;
};

export const saveQuestionnaireValues = (sqId: string, operations: Operation[]): Promise<Record<string, string>> => {
    return patch(`/api-candidate/${sqId}/questionnaire`, operations) as Promise<Record<string, string>>;
};

export const finishQuestionnaire = (sqId: string): Promise<void> => {
    return post(`/api-candidate/${sqId}/questionnaire/complete`, {}, true) as Promise<void>;
};

export const optOut = (sqId: string): Promise<void> => {
    return post(`/api-candidate/${sqId}/questionnaire/optOut`, {}, true) as Promise<void>;
};

export const getForm = async (sqId: string): Promise<Blob> => {
    const response = await doFetchOther(`/api-candidate/${sqId}/form/F8850`);
    return response.blob();
};

export type SignaturePin = { pin: string };

export const signForm = (sqId: string, args: SignaturePin): Promise<void> => {
    return post(`/api-candidate/${sqId}/form/F8850/sign`, args, true) as Promise<void>;
};

export const getRequests = (sqId: string): Promise<Request[]> => {
    return get(`/api-candidate/${sqId}/request`) as Promise<Request[]>;
};

export interface FileContainer {
    index: number;
    file: File;
    callbacks: UploadCallbacks;
}

export interface UploadDocumentArgs {
    requestId: string;
    fileContainer: FileContainer;
}

export const uploadDocument = async (sqId: string, args: UploadDocumentArgs): Promise<Document> => {
    return uploadFile(`/api-candidate/${sqId}/requests/${args.requestId}/upload`, args.fileContainer);
};

export const deleteDocument = async (sqId: string, requestId: string, documentId: string): Promise<void> => {
    return doDelete(`/api-candidate/${sqId}/requests/${requestId}/document/${documentId}`) as Promise<void>;
};

export type DownloadDocumentArgs = {
    sqId: string;
    requestId: string;
    documentId: string;
    progressCallback: (progress: number) => void;
};

export const downloadDocument = async (args: DownloadDocumentArgs): Promise<Blob> => {
    const response = await downloadFileWithProgress(
        `/api-candidate/${args.sqId}/requests/${args.requestId}/document/${args.documentId}/download`,
        args.progressCallback
    );

    if (response.ok) {
        return response.blob();
    } else {
        throw await response.json();
    }
};

export const getStates = (): Promise<StateResponse[]> => {
    return Promise.resolve([
        {
            code: "AL",
            name: "Alabama",
        },
        {
            code: "AK",
            name: "Alaska",
        },
        {
            code: "AZ",
            name: "Arizona",
        },
        {
            code: "AR",
            name: "Arkansas",
        },
        {
            code: "CA",
            name: "California",
        },
        {
            code: "CO",
            name: "Colorado",
        },
        {
            code: "CT",
            name: "Connecticut",
        },
        {
            code: "DE",
            name: "Delaware",
        },
        {
            code: "DC",
            name: "District of Columbia",
        },
        {
            code: "FL",
            name: "Florida",
        },
        {
            code: "GA",
            name: "Georgia",
        },
        {
            code: "HI",
            name: "Hawaii",
        },
        {
            code: "ID",
            name: "Idaho",
        },
        {
            code: "IL",
            name: "Illinois",
        },
        {
            code: "IN",
            name: "Indiana",
        },
        {
            code: "IA",
            name: "Iowa",
        },
        {
            code: "KS",
            name: "Kansas",
        },
        {
            code: "KY",
            name: "Kentucky",
        },
        {
            code: "LA",
            name: "Louisiana",
        },
        {
            code: "ME",
            name: "Maine",
        },
        {
            code: "MD",
            name: "Maryland",
        },
        {
            code: "MA",
            name: "Massachusetts",
        },
        {
            code: "MI",
            name: "Michigan",
        },
        {
            code: "MN",
            name: "Minnesota",
        },
        {
            code: "MS",
            name: "Mississippi",
        },
        {
            code: "MO",
            name: "Missouri",
        },
        {
            code: "MT",
            name: "Montana",
        },
        {
            code: "NE",
            name: "Nebraska",
        },
        {
            code: "NV",
            name: "Nevada",
        },
        {
            code: "NH",
            name: "New Hampshire",
        },
        {
            code: "NJ",
            name: "New Jersey",
        },
        {
            code: "NM",
            name: "New Mexico",
        },
        {
            code: "NY",
            name: "New York",
        },
        {
            code: "NC",
            name: "North Carolina",
        },
        {
            code: "ND",
            name: "North Dakota",
        },
        {
            code: "OH",
            name: "Ohio",
        },
        {
            code: "OK",
            name: "Oklahoma",
        },
        {
            code: "OR",
            name: "Oregon",
        },
        {
            code: "PA",
            name: "Pennsylvania",
        },
        {
            code: "RI",
            name: "Rhode Island",
        },
        {
            code: "SC",
            name: "South Carolina",
        },
        {
            code: "SD",
            name: "South Dakota",
        },
        {
            code: "TN",
            name: "Tennessee",
        },
        {
            code: "TX",
            name: "Texas",
        },
        {
            code: "UT",
            name: "Utah",
        },
        {
            code: "VT",
            name: "Vermont",
        },
        {
            code: "VA",
            name: "Virginia",
        },
        {
            code: "WA",
            name: "Washington",
        },
        {
            code: "WV",
            name: "West Virginia",
        },
        {
            code: "WI",
            name: "Wisconsin",
        },
        {
            code: "WY",
            name: "Wyoming",
        },
        {
            code: "AS",
            name: "American Samoa",
        },
        {
            code: "GU",
            name: "Guam",
        },
        {
            code: "MP",
            name: "Commonwealth of the Northern Mariana Islands",
        },
        {
            code: "PR",
            name: "Puerto Rico",
        },
        {
            code: "UM",
            name: "U.S. Minor Outlying Islands",
        },
        {
            code: "VI",
            name: "United States Virgin Islands",
        },
    ]);
};
