﻿import { defineStyle, defineStyleConfig, extendTheme } from "@chakra-ui/react";
import { ChakraTheme } from "@chakra-ui/theme";
import { theme as coreTheme } from "am-tax-fe-core";

const themeOverrides = {
    colors: {
        blue: {
            25: "#f4f8fa",
            50: "#ecf3f7",
            75: "#e4eef3",
            100: "#d8e6ee",
            150: "#c4d9e5",
            200: "#b0ccdc",
            300: "#89b3cb",
            400: "#6199b9",
            500: "#3a7fa8",
            600: "#2b6990",
            700: "#1c5377",
            800: "#0e3f60",
            900: "#002B49", // brand primary color dark blue
        },
    },
    components: {
        Button: defineStyleConfig({
            defaultProps: defineStyle({
                size: "lg",
            }),
        }),
    },
} as Partial<ChakraTheme>;

export const defaultTheme = extendTheme(coreTheme, themeOverrides);
