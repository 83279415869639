﻿import { createContext, FunctionComponent, PropsWithChildren, useContext } from "react";
import { Outlet, useParams } from "react-router-dom";

const SqIdContext = createContext("0");
export const useSqId = () => useContext(SqIdContext);

export const SqIdContextProvider: FunctionComponent<PropsWithChildren> = () => {
    const { sqId } = useParams();

    if (!sqId) {
        throw new Error("SqId is required");
    }

    return (
        <SqIdContext.Provider value={sqId}>
            <Outlet />
        </SqIdContext.Provider>
    );
};
