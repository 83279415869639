import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./i18n"; // even though we aren't using this directly here, it needs to imported so that it gets bundled
import { pathToRegexp } from "path-to-regexp";
import { configureBaseUrl, getConfig, getEnvConfig, getTheme } from "./api";

(async function () {
    if (import.meta.env.VITE_USE_MSW_CUSTOM_MOCKS === "true") {
        // @ts-ignore
        const setup = await import("./api/msw/setupForBrowser.ts");
        const { worker } = setup;
        await worker.start();
    }

    const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

    try {
        // parse the sqId from the url
        const pattern = pathToRegexp("/:sqId/(.*)");
        const match = pattern.exec(location.pathname);
        const sqId = match && match[1] ? match[1] : "";

        // grab the enfConfig from session storage if it exists, otherwise fetch it
        const envConfigStr = sessionStorage.getItem("envConfig");
        let envConfig = envConfigStr ? JSON.parse(envConfigStr) : null;
        if (!envConfig) {
            envConfig = await getEnvConfig();
            sessionStorage.setItem("envConfig", JSON.stringify(envConfig));
        }
        configureBaseUrl(envConfig.apiUrl);

        // get the config object and theme
        const config = await getConfig(sqId);
        document.title = config.clientName;

        const theme = config.themePath && config.themePath.length > 0 ? await getTheme(config.themePath) : null;

        root.render(
            <React.StrictMode>
                <App sqId={sqId} envConfig={envConfig} config={config} theme={theme} />
            </React.StrictMode>
        );
    } catch (e) {
        root.render(
            <div style={{ height: "100%", display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                Unable to configure the application for the given URL.
            </div>
        );
    }
})();
