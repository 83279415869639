﻿import { CreateToastFnReturn, useToast as useToastOriginal, UseToastOptions } from "@chakra-ui/react";
import { hashQueryKey, QueryKey } from "@tanstack/react-query";

// This is a passthrough to the useToast from chakra, but if toast the same message within a second, the subsequent toast is ignored.
export function useToast(options?: UseToastOptions) {
    const toast: CreateToastFnReturn = useToastOriginal(options);

    return (options?: UseToastOptions) => {
        if (options) {
            if (hasNotToastedForThisMessageKeyWithinTheLastSecond(options)) {
                toast(options);
            }
        } else {
            toast(options);
        }
    };
}

// Because the same query is issued from multiple places in the app, failures are toasted multiple times. This version of useToast solves that problem.
export function useToastQueryError(options?: UseToastOptions) {
    const toast = useToast(options);

    return (queryKey: QueryKey, options?: UseToastOptions) => {
        if (hasNotToastedForThisQueryKeyWithinTheLastSecond(queryKey)) {
            toast(options);
        }
    };
}

type QueryError = {
    time: number;
    queryKeyHash: string;
};
const queryErrors: Array<QueryError> = [];

// The point of this function is to quiet down the excessive number of toasts that can happen when a query fails.
function hasNotToastedForThisQueryKeyWithinTheLastSecond(queryKey: QueryKey): boolean {
    const now = Date.now();
    const queryKeyHash = hashQueryKey(queryKey);
    let result = true;

    //clear out errors that happened more than 1 second ago
    while (queryErrors.length > 0 && queryErrors[0].time < now - 1000) {
        queryErrors.shift();
    }

    for (const error of queryErrors) {
        if (error.queryKeyHash === queryKeyHash) {
            result = false;
        }
    }

    queryErrors.push({ time: now, queryKeyHash });

    return result;
}

type ToastMessage = {
    time: number;
    messageHash: string;
};
const messages: Array<ToastMessage> = [];
function hashMessage(options: UseToastOptions): string {
    return `${options.title}-${options.description}-${options.status}`;
}

// The point of this function is to quiet down the excessive number of similar toasts that can happen at the same time.
// It is very similar to the function above, but applied generally to the message, not the query key.
function hasNotToastedForThisMessageKeyWithinTheLastSecond(options: UseToastOptions): boolean {
    const now = Date.now();
    const messageHash = hashMessage(options);
    let result = true;

    //clear out errors that happened more than 1 second ago
    while (messages.length > 0 && messages[0].time < now - 1000) {
        messages.shift();
    }

    for (const message of messages) {
        if (message.messageHash === messageHash) {
            result = false;
        }
    }

    messages.push({ time: now, messageHash: messageHash });

    return result;
}
