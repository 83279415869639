﻿import { useEffect, createContext, FunctionComponent, PropsWithChildren, useContext } from "react";
import { Outlet, useSearchParams } from "react-router-dom";

const ReplyUrlContext = createContext("");
export const useReplyUrl = () => useContext(ReplyUrlContext);

export const ReplyUrlContextProvider: FunctionComponent<PropsWithChildren> = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const replyUrlParam = searchParams.get("replyUrl");
    const wotcReplyUrl = sessionStorage.getItem("wotcReplyUrl");

    useEffect(() => {
        if (replyUrlParam && replyUrlParam.length > 0) {
            if (wotcReplyUrl !== replyUrlParam) {
                sessionStorage.setItem("wotcReplyUrl", replyUrlParam);
                searchParams.delete("replyUrl");
                setSearchParams(searchParams);
            }
        }
    }, [replyUrlParam, wotcReplyUrl]);

    return (
        <ReplyUrlContext.Provider value={sessionStorage.getItem("wotcReplyUrl") ?? ""}>
            <Outlet />
        </ReplyUrlContext.Provider>
    );
};
