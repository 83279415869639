import { createQueryKeyStore } from "@lukemorales/query-key-factory";

export const queryKeys = createQueryKeyStore({
    config: {
        byLanguage: (sqId: string, language: string) => [sqId, language],
    },
    account: {
        me: (sqId: string) => [sqId],
    },
    candidates: {
        me: (sqId: string) => [sqId],
    },
    requests: {
        bySqId: (sqId: string) => [sqId],
    },
    questionnaire: {
        byLanguage: (sqId: string, language: string) => [sqId, language],
        values: (sqId: string) => [sqId],
    },
    form: {
        f8850: (sqId: string) => [sqId],
    },
    states: {
        all: null,
    },
});
