﻿import { createContext, FunctionComponent, PropsWithChildren, useMemo } from "react";
import { AppInsightsContext, ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { useEnvironmentContext } from "../EnvironmentContext";

const TelemetryContext = createContext({});

export const TelemetryContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const { aiConnectionString, aiCloudRole, aiCloudRoleInstance } = useEnvironmentContext();
    const authToken = sessionStorage.getItem("authToken"),
        { authId, accountId } = getAuthId(authToken ?? "");

    const aiReact = useMemo(() => {
        console.log("aiReact churn", [authId, aiConnectionString]);
        const reactPlugin = new ReactPlugin(),
            appInsights = new ApplicationInsights({
                config: {
                    connectionString: aiConnectionString,
                    enableAutoRouteTracking: false, //disabled because it was high-churn
                    extensions: [reactPlugin],
                },
            });

        appInsights.loadAppInsights();
        appInsights.context.application.build = import.meta.env.VITE_BUILD_NUMBER;
        appInsights.context.application.ver = import.meta.env.VITE_BUILD_NUMBER;

        if (authId) {
            appInsights.setAuthenticatedUserContext(authId, accountId, undefined);
        }

        appInsights.addTelemetryInitializer(item => {
            if (item.tags) {
                item.tags["ai.cloud.role"] = aiCloudRole;
                item.tags["ai.cloud.roleInstance"] = aiCloudRoleInstance;
            }
        });

        return reactPlugin;
    }, [aiConnectionString, authId, accountId]);

    return (
        <TelemetryContext.Provider value={{}}>
            <AppInsightsContext.Provider value={aiReact}>{children}</AppInsightsContext.Provider>
        </TelemetryContext.Provider>
    );
};

function getAuthId(token: string): { authId: string; accountId: string } {
    if (token) {
        const base64Url = token.split(".")[1];
        const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        const jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split("")
                .map(function (c) {
                    return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join("")
        );

        const payload = JSON.parse(jsonPayload),
            nameId = payload["nameid"],
            tenantId = payload["http://schemas.alvarezandmarsaltax.com/wotc/identity/claims/TenantId"],
            entityId = payload["http://schemas.alvarezandmarsaltax.com/wotc/identity/claims/EntityId"];

        return { authId: nameId, accountId: `${tenantId}:${entityId}` };
    }

    return { authId: "", accountId: "" };
}
