﻿import { createContext, FunctionComponent, PropsWithChildren, useContext, useMemo } from "react";
import { IEnvironmentContext } from "./EnvironmentContext.types";
import { ConfigResponse, ErrorResponse, IEnvConfig, queryKeys } from "../../api";
import { onlineManager, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { IconPlugConnectedX } from "@tabler/icons-react";
import { useToast } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { useTranslation } from "react-i18next";

const defaultState: IEnvironmentContext = {
    apiUrl: "",
    aiConnectionString: "",
    aiCloudRole: "",
    aiCloudRoleInstance: "",
};

const EnvironmentContext = createContext<IEnvironmentContext>(defaultState);

function getServerDownMessage<TError>(error: TError) {
    // @ts-ignore
    if (error.message === "Failed to fetch") {
        return "Unable to connect to the server. Please try again later.";
    }
}

type EnvironmentContextProviderProps = PropsWithChildren<{
    sqId: string;
    config: ConfigResponse;
    envConfig: IEnvConfig;
}>;

export const EnvironmentContextProvider: FunctionComponent<EnvironmentContextProviderProps> = ({ sqId, config, envConfig, children }) => {
    const toast = useToast();
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const aiConnectionString = envConfig?.aiConnectionString ?? "",
        aiCloudRole = envConfig?.aiCloudRole ?? "",
        aiCloudRoleInstance = envConfig?.aiCloudRoleInstance ?? "";

    const contextValue = {
        apiUrl: envConfig?.apiUrl ?? "",
        aiConnectionString,
        aiCloudRole,
        aiCloudRoleInstance,
    };

    if (import.meta.env.VITE_OFFLINE_MODE === "true") {
        // this probably seems backwards, but but we want to tell react query to act like it is online even if it is offline
        onlineManager.setOnline(true);
    }

    function handleError(error: unknown, defaultMessage: string, message?: string | undefined) {
        const serverDownMessage = getServerDownMessage(error);
        let errorMessage = defaultMessage;
        if (message) {
            errorMessage = message;
        }
        if (serverDownMessage) {
            errorMessage = serverDownMessage;
        }

        toast({
            title: "Error",
            description: `${errorMessage}`,
            status: "error",
            isClosable: true,
            duration: 6000,
            icon: <IconPlugConnectedX />,
        });

        // @ts-ignore
        if (error?.status === 401 || error?.status === 403) {
            navigate("/unauthorized");
        }
    }

    const queryClient = useMemo(() => {
        const qClient = new QueryClient({
            defaultOptions: {
                queries: {
                    retry: 0,
                    cacheTime: 30 * 60 * 1000,
                    staleTime: 15 * 60 * 1000,
                },
                mutations: {
                    onError: (error: unknown, variables: unknown, context: unknown) => {
                        const errorResponse = error as ErrorResponse;
                        if (errorResponse.status != 422) {
                            handleError(error, "Unable to perform operation. Please try again.");
                        }
                    },
                },
            },
            queryCache: new QueryCache({
                onError: (error: unknown, query) => {
                    let errorMessage: string | undefined;
                    if (query?.meta?.errorMessage) {
                        errorMessage = query.meta.errorMessage as string;
                    }
                    handleError(error, "Unable to load data. Please try again.", errorMessage);
                },
            }),
        });

        qClient.setQueryData(queryKeys.config.byLanguage(sqId, i18n.language).queryKey, config);

        return qClient;
    }, [config, i18n, navigate, sqId, toast]);

    return (
        <EnvironmentContext.Provider value={contextValue}>
            <QueryClientProvider client={queryClient}>
                {children}
                {import.meta.env.VITE_ENABLE_REACT_QUERY_DEV_TOOLS === "true" && <ReactQueryDevtools initialIsOpen={false} />}
            </QueryClientProvider>
        </EnvironmentContext.Provider>
    );
};

export const useEnvironmentContext = () => useContext(EnvironmentContext);
